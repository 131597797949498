import { FC, useEffect, useState } from "react";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { useLazyGetTotalEmissionsByOrganizationQuery } from "../../store/dailyEmissions/api";
import { Select, Button, Form } from "antd";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import DailyEmissionSelection from "../../components/DailyEmissions/SelectionComponent/DailyEmissionSelection";
import { QuantityUnit } from "../../helpers/unitsHelpers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  endTimestampSelector,
  startTimestampSelector,
} from "../../store/selections/selector";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import {
  setEndTimestamp,
  setStartTimestamp,
} from "../../store/selections/slice";
import { plotColors } from "../../helpers/plotColors";
import { useGetOrganizationsQuery } from "../../store/organizations/api";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";
import { generateDateLabels, parseTotalEmissionsToKg } from "./helpers";
import styles from "./styles.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const KPIReport: FC = () => {
  const [form] = Form.useForm();
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const dispatch = useAppDispatch();
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const [units, setEmissionUnits] = useState(QuantityUnit.MCF);
  const userData = useGetCurrentUserQuery(null);
  const isAdmin = userData?.data?.isAdmin ?? false;
  const organizations = useGetOrganizationsQuery(null, { skip: !isAdmin }).data;
  const [getTotalEmissionsByOrgTrigger, result] =
    useLazyGetTotalEmissionsByOrganizationQuery();

  let startDate = "";
  let endDate = "";
  let labels: string[] = [];

  if (result.data?.range.endDate != null) {
    endDate = result.data?.range.endDate;
  }
  if (result.data?.range.startDate != null) {
    startDate = result.data?.range.startDate;
  }
  if (startDate !== "" && endDate !== "") {
    labels = generateDateLabels(startDate, endDate);
  }

  let totalEmissionsPerSite =
    result.data !== undefined ? result.data.totalEmissionsPerSite : [];

  if (units !== QuantityUnit.MCF) {
    totalEmissionsPerSite = parseTotalEmissionsToKg(
      totalEmissionsPerSite,
      globalConfig
    );
  }

  const highestEmittersDataset = totalEmissionsPerSite.map((el, index) => {
    return {
      label: el.siteName,
      data: [el.totalEmissions],
      backgroundColor: plotColors[index],
    };
  });

  const highestEmittersLabels = [`Highest Emitters (${units})`];

  const highestEmittersData = {
    datasets: highestEmittersDataset,
    labels: highestEmittersLabels,
  };

  const datasets = totalEmissionsPerSite.map((item, index) => {
    const tableData = labels.map((label) => {
      const result = item.dailyEmissions.find(
        (dataEl) => dataEl.Timestamp === label
      );
      if (result !== undefined) return result["totalEmission(MCF)"];
      return null;
    });
    return {
      label: item.siteName,
      data: tableData,
      backgroundColor: plotColors[index],
    };
  });
  const data = {
    labels,
    datasets,
  };

  const highestEmittersOptions = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: `Highest Emitters (${units})`,
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: `Daily Emissions Per Site ${units}`,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const pieChartLabels = totalEmissionsPerSite.map((el) => el.siteName);
  const pieChartDataset = [
    {
      label: `Total Site Emissions (${units})`,
      data: totalEmissionsPerSite.map((el) => el.totalEmissions),
      backgroundColor: plotColors,
      borderWidth: 1,
    },
  ];

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `Total Organization Emissions ${units}`,
      },
    },
  };

  const pieChartObject = {
    labels: pieChartLabels,
    datasets: pieChartDataset,
  };
  const onGetOrgDailyEmissions = async (): Promise<void> => {
    if (currentOrg !== null && startTimestamp !== "" && endTimestamp !== "") {
      await getTotalEmissionsByOrgTrigger({
        id: currentOrg.OrgId,
        startDate: startTimestamp,
        endDate: endTimestamp,
      }).unwrap();
    }
  };
  const orgOptions = isAdmin
    ? organizations?.map((org) => {
      return {
        label: org.name,
        value: org.OrgId,
      };
    })
    : userData.data !== undefined
      ? userData.data.organizations.map((el) => {
        if (typeof el !== "string") {
          return {
            value: el.OrgId,
            label: el.name,
          };
        } else {
          return {
            value: el,
            label: "",
          };
        }
      })
      : [];
  const handleChangeUnits = (newUnit: QuantityUnit): void => {
    setEmissionUnits(newUnit);
  };

  const setSelectedOrg = (selectedOrgId: string): void => {
    const organization = organizations?.find(
      (org) => org.OrgId === selectedOrgId
    );
    if (organization !== undefined) {
      dispatch(setCurrentOrganization(organization));
    }
  };

  useEffect(() => {
    const fetchOrganizations = async (): Promise<void> => {
      try {
        if (isAdmin && currentOrg !== null) {
          await getTotalEmissionsByOrgTrigger({
            id: currentOrg.OrgId,
          })
            .unwrap()
            .then((res) => {
              const _startDate = res.range.startDate;
              const _endDate = res.range.endDate;
              dispatch(setStartTimestamp(_startDate));
              dispatch(setEndTimestamp(_endDate));
            });
        } else {
          const organizations = userData.data?.organizations;

          if (
            organizations !== null &&
            organizations !== undefined &&
            organizations.length > 0 &&
            typeof organizations[0] !== "string"
          ) {
            await getTotalEmissionsByOrgTrigger({
              id: organizations[0].OrgId,
            })
              .unwrap()
              .then((res) => {
                const _startDate = res.range.startDate;
                const _endDate = res.range.endDate;
                dispatch(setStartTimestamp(_startDate));
                dispatch(setEndTimestamp(_endDate));
              });
            dispatch(setCurrentOrganization(organizations[0]));
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (userData.data !== null) {
      void fetchOrganizations();
    }
  }, [userData.data, getTotalEmissionsByOrgTrigger]);

  return (
    <div className={styles.KPIReport}>
      <div className={styles.ControlsWrapper}>
        <h2>KPI Reports</h2>
        <div className={styles.flex}>
          <div className={styles.SelectOrganizationWrapper}>
            <div className={styles.marginBottom15}>Select Organization</div>
            <Select
              className={styles.width100}
              options={orgOptions}
              onChange={(e: string) => setSelectedOrg(e)}
              disabled={!isAdmin}
              defaultValue={currentOrg?.OrgId}
            />
          </div>
          <div>
            <div className={styles.marginBottom15}>Select Time Range</div>
            <DailyEmissionSelection
              form={form}
              units={units}
              onChangeUnits={handleChangeUnits}
              minDisplayDays={1}
            />
          </div>
          <div className={styles.alignCenter}>
            <Button onClick={onGetOrgDailyEmissions} type="primary">
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.width100}>
        <div className={styles.graphsWrapper}>
          <div className={styles.width50}>
            <Pie data={pieChartObject} options={pieChartOptions} />
          </div>
          <div className={styles.width50}>
            <Bar data={highestEmittersData} options={highestEmittersOptions} />
          </div>
        </div>
        <div className={styles.paddingBottom}>
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default KPIReport;
