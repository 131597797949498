export const plotColors = [
  "#667F62", // Dark Olive Green
  "#D18A6E", // Dark Terra Cotta
  "#C56262", // Dark Maroon
  "#B37853", // Darker Clay
  "#6B5CA5", // Darker Violet
  "#5C87A3", // Dark Slate Blue
  "#CC8762", // Darker Sand
  "#A26A6A", // Darker Rosewood
  "#71A18E", // Dark Sea Green
  "#D07A5A", // Dark Coral Sand
  "#958F60", // Dark Khaki
  "#7D716C", // Dark Taupe
  "#9F8EAE", // Darker Lavender Gray
  "#6A7E8C", // Darker Steel Blue
  "#A98271", // Darker Coffee
  "#8A726B", // Darker Dust Brown
  "#87A48E", // Darker Moss Green
  "#CA9280", // Dark Blush Pink
  "#6E5B8C", // Darker Purple Blue
  "#9D6D5A", // Darker Chestnut
];
