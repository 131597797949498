import { mcfToKg } from "../../helpers/unitsHelpers";
import { TotalEmissionsPerSite } from "../../store/dailyEmissions/types";
import { GlobalConfig } from "../../store/globalConfig/types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

export const parseTotalEmissionsToKg = (
  data: TotalEmissionsPerSite[],
  globalConfig: GlobalConfig | undefined
): TotalEmissionsPerSite[] => {
  const result = data.map((item) => {
    const sumOfTotalEmissions = mcfToKg(item.totalEmissions, globalConfig);
    const dailyEmissions = item.dailyEmissions.map((de) => {
      const totalEmissions = mcfToKg(de["totalEmission(MCF)"], globalConfig);
      return {
        ...de,
        "totalEmission(MCF)": totalEmissions,
      };
    });
    return {
      ...item,
      dailyEmissions,
      totalEmissions: sumOfTotalEmissions,
    };
  });
  return result;
};

export const generateDateLabels = (
  startDate: string,
  endDate: string
): string[] => {
  const labels = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isSameOrBefore(dayjs(endDate))) {
    labels.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return labels;
};
