import { useEffect, useRef, useState } from "react";
import BingMapRenderer from "../../components/BingMapRenderer/BingMapRenderer";
import {
  addNodePushpins,
  addPushpins,
  createNodePushPin,
  handleToggleDroneImageLayer,
  makeMap,
  removePushPins,
} from "./mapApi";
import styles from "./style.module.scss";
import MapController from "./MapController/OrgMapController";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import { defaultPointObject } from "../SiteMapv2/helpers";
import {
  cleanGridDrawing,
  getCellMatrix,
  handleToggleNodesLayer,
  handleToggleWindsLayer,
  initializeMapVariables,
  removeGrid,
  removeWindVector,
} from "../SiteMapv2/mapApi";
import {
  generateTimestamp,
  subtractMinutes,
  addMinutes,
} from "../../helpers/dateTimeHelpers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { currentSiteSelector } from "../../store/sites/selector";
import { setShowMapController } from "../../store/orgMap/slice";
import {
  siteGridConfig,
  useCreateSiteGridConfigMutation,
} from "../../store/gridConfig/api";
import {
  siteGridsApi,
  useCreateSiteGridsMutation,
} from "../../store/siteGrids/api";
import {
  gpaqsAlarmIncidents,
  useUpdateGpaqsAlarmIncidentMutation,
} from "../../store/gpaqsAlarmIncidents/api";
import { siteNodesApi } from "../../store/nodes/api";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { ppmForSiteMapApi } from "../../store/ppmForSiteMap/api";
import { singleNodeSitePAQSApi } from "../../store/singleNodeSitePAQS/api";
import {
  applyMapLayersForDrone,
  applyMapLayersForDroneWithVirtualGrid,
  removeDroneLayersGrid,
} from "../SiteMapv2/layerControllerForDroneImages";
import {
  calculateWindVelocity,
  polarToCartesian,
} from "../SiteMapv2/windHelper";
import { convertTimezone, TIMESTAMP_FORMAT } from "../../helpers/dayjsHelpers";
import AlarmIncidentPanel from "./AlarmIncidentPanel/AlarmIncidentPanel";
import PPMPanel from "./PPMPanel/PPMPanel";
import { showMapControllerSelector } from "../../store/orgMap/selector";
import {
  endTimestampSelector,
  startTimestampSelector,
} from "../../store/selections/selector";
import {
  setStartTimestamp,
  setEndTimestamp,
} from "../../store/selections/slice";
import {
  formatDate,
  getNewStartTimestamp,
  getNewEndTimestamp,
} from "../../components/TimeRangePicker/helpers";
import { toggleGPAQSLayerHelper } from "./helpers/toggleGPAQSLayer";
import { generatePushPins } from "./cellToCoordinatesHelper";
import { customerAlarmIncidentApi } from "../../store/alarmIncidents/api";
import { setSideBarElements } from "../../store/ui/slice";
import { siteImageApi } from "../../store/siteImage/api";
import { applyMapLayers } from "../SiteMapv2/layerController";
import { currentAlarmIncidentSelector } from "../../store/gpaqsAlarmIncidents/selector";
import { setCurrentAlarmIncident } from "../../store/gpaqsAlarmIncidents/slice";
import dayjs from "dayjs";

const OrganizationMap = () => {
  const dispatch = useAppDispatch();
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const currentSite = useAppSelector(currentSiteSelector);
  const showMapController = useAppSelector(showMapControllerSelector);
  const viewOptions =
    currentSite === null
      ? {
          center: {
            latitude: 38.049518,
            longitude: -94.852874,
          },
          mapTypeId: "aerial",
          zoom: 5,
        }
      : {
          center: {
            latitude: currentSite.details.latAPI,
            longitude: currentSite.details.longAPI,
          },
          mapTypeId: "aerial",
          zoom: 18,
        };
  const mapContainerRef = useRef(null);
  const alarmPlayingRef = useRef(null);
  const [point1, setPoint1] = useState(defaultPointObject);
  const [point2, setPoint2] = useState(defaultPointObject);
  const siteId = currentSite !== null ? currentSite.SiteId : "";
  const timezone = currentSite !== null ? currentSite.timezone : "UTC";
  const [getSiteGridConfigTrigger, siteGridConfigResult] =
    siteGridConfig.endpoints.getSiteGridConfig.useLazyQuery();
  const [getSiteGridsTrigger, siteGridsResult] =
    siteGridsApi.endpoints.getSiteGrids.useLazyQuery();
  const [createSiteGridCofigTrigger] = useCreateSiteGridConfigMutation();
  const [selectedAlarmIncident, setSelectedAlarmIncident] = useState(null);
  const [showSiteGrid, setShowSiteGrid] = useState(false);
  const [showIncidentPanel, setShowIncidentPanel] = useState(false);
  const [incidentActualLeakSource, setIncidentActualLeakSource] = useState("");
  const [showActiveCells, setShowActiveCells] = useState(false);
  const [additionalHighlightedCells, setAdditionalHighlightedCells] = useState(
    []
  );
  const [incidentType, setIncidentType] = useState("");
  const [incidentNotes, setIncidentNotes] = useState("");
  const [selectIncidentActualLeakSource, setSelectIncidentActualSource] =
    useState(false);
  const [isPpmToggled, setIsPpmToggled] = useState(false);
  const [showAvgEmissions, setShowAvgEmissions] = useState(false);
  const onToggleAvgEmissions = (newValue) => {
    if (newValue !== undefined) {
      setShowAvgEmissions(newValue);
    } else {
      setShowAvgEmissions((lastToggle) => !lastToggle);
    }
  };
  const getCurrentTimeWithoutSecondsAndMilliseconds = () => {
    const now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);
    return now;
  };

  const [endTimestampPPM, setEndTimestampPPM] = useState(() => {
    const endTime = new Date(endTimestamp);
    const now = getCurrentTimeWithoutSecondsAndMilliseconds();
    if (endTime.getTime() - now.getTime() > 0) {
      return formatDate(now);
    }
    return endTimestamp;
  });
  const hourBeforeEndTimestampPPM = getNewStartTimestamp(endTimestampPPM, 0, 1);
  const [showLocalTimeForMapPPM, setShowLocalTimeForMapPPM] = useState(true);
  const [startTimestampPPM, setStartTimestampPPM] = useState(
    hourBeforeEndTimestampPPM
  );
  const handleChangeTimestampPPM = (newTime) => {
    setStartTimestampPPM(newTime);
    setEndTimestampPPM(getNewEndTimestamp(newTime, 0, 1));
  };
  const [startDatePPM, startTimePPM] = startTimestampPPM.split(" ");
  const [selectedPpmData, setSelectedPpmData] = useState(null);
  const [selectedEditType, setSelectedEditType] = useState(1);
  const [selectedCellToEdit, setSelectedCellToEdit] = useState(null);
  const [formKey, setFormKey] = useState(0);
  const [showWinds, setShowWinds] = useState(false);
  const animationIdRef = useRef(null);
  const [isPPMPlaying, setisPPMPlaying] = useState(false);
  const [showNodesLayer, setShowNodesLayer] = useState(false);
  const [showDroneImageLayer, setShowDroneImageLayer] = useState(false);
  const [showSamplingRatesLayer, setShowSamplingRatesLayer] = useState(false);
  const [samplingRatesTarget, setSamplingRatesTarget] = useState("");
  const [showGPAQSLayer, setShowGPAQSLayer] = useState(false);
  const [selectedGPAQSLargestValue, setSelectedGPAQSLargestValue] = useState(0);
  const [newIncident, setNewIncident] = useState(false);
  const [alarmBackcalculation, setAlarmBackcalculation] = useState(null);
  const [showVOCLevels, setShowVOCLevels] = useState(false);
  const [ppmEndDateHours, setPPMEndDateHours] = useState(1);

  const _selectedAlarmIncident = useAppSelector(currentAlarmIncidentSelector);

  const [triggerGpaqsAlarmIncidentsQuery, gpaqsAlarmIncidentsResult] =
    gpaqsAlarmIncidents.endpoints.getGpaqsAlarmIncidents.useLazyQuery();

  const [updateGpaqsAlarmIncidentMutation] =
    useUpdateGpaqsAlarmIncidentMutation();

  const [customerAlarmIncidentsTrigger] =
    customerAlarmIncidentApi.endpoints.getCustomerAlarmIncidents.useLazyQuery();

  const gpaqsAlarmIncidentsEndDateObj = new Date(endTimestamp);
  gpaqsAlarmIncidentsEndDateObj.setHours(0);
  gpaqsAlarmIncidentsEndDateObj.setMinutes(0);
  gpaqsAlarmIncidentsEndDateObj.setSeconds(0);
  gpaqsAlarmIncidentsEndDateObj.setMilliseconds(0);
  const gpaqsAlarmIncidentsEndTimestamp = formatDate(
    gpaqsAlarmIncidentsEndDateObj
  );
  const gpaqsAlarmIncidentsStartTimestamp = getNewStartTimestamp(
    gpaqsAlarmIncidentsEndTimestamp,
    1,
    0
  );

  // Buffered timestamps solve the nested event timestamp issue for GPAQS alarms.
  // Pull all alarms within 8 hours of requested time range,
  // then filter for GpaqsAlarmIncident.most_likely_event.Timestamp within
  // requested (non-buffered) time range.
  const startWithBuffer = subtractMinutes(
    gpaqsAlarmIncidentsStartTimestamp,
    60 * 8
  );
  const endWithBuffer = addMinutes(gpaqsAlarmIncidentsEndTimestamp, 60 * 8);
  const gpaqsAlarmIncidentsPayload = {
    startDate: startWithBuffer,
    endDate: endWithBuffer,
    siteId,
    timezone,
  };

  let siteGpaqsAlarmIncidents =
    gpaqsAlarmIncidentsResult?.data?.gpaqsAlarms ?? [];

  const rangeMin = convertTimezone(
    gpaqsAlarmIncidentsStartTimestamp,
    timezone,
    "UTC"
  );
  siteGpaqsAlarmIncidents = siteGpaqsAlarmIncidents.filter((alarmIncident) => {
    const startTime =
      alarmIncident.type === "gpaqsAlarm"
        ? dayjs(alarmIncident.most_likely_event.Timestamp)
        : dayjs(alarmIncident.Timestamp);
    const isInRange =
      startTime.isAfter(dayjs(rangeMin)) &&
      startTime.isBefore(dayjs(rangeMin).add(24, "hours"));

    return isInRange;
  });

  const fetchingAlarms = gpaqsAlarmIncidentsResult.isFetching;

  const onAlarmIncidentsDateChange = (date, dateString) => {
    const newEndTimestampDateObj = new Date(dateString + " 00:00:00");
    newEndTimestampDateObj.setDate(newEndTimestampDateObj.getDate() + 1);
    newEndTimestampDateObj.setHours(0);
    newEndTimestampDateObj.setMinutes(0);
    newEndTimestampDateObj.setSeconds(0);
    newEndTimestampDateObj.setMilliseconds(0);
    const newEndTimestamp = formatDate(newEndTimestampDateObj);
    const newStartTimestamp = getNewStartTimestamp(newEndTimestamp, 1, 0);
    handleChangeTimestampPPM(newStartTimestamp);

    const siteStartTimestamp = dateString + " 00:00:00";
    const siteEndTimestamp = dayjs(siteStartTimestamp)
      .add(1, "day")
      .format(TIMESTAMP_FORMAT);

    const utcStartTimestamp = convertTimezone(
      siteStartTimestamp,
      timezone,
      "UTC"
    );
    const utcEndTimestamp = convertTimezone(siteEndTimestamp, timezone, "UTC");

    dispatch(setStartTimestamp(utcStartTimestamp));
    dispatch(setEndTimestamp(utcEndTimestamp));
  };

  const requireDroneImage =
    currentSite === null ? false : currentSite.requireDroneImage;

  const isMultipleGrid =
    currentSite === null ? false : currentSite.isMultipleGrid;

  const nodesPayload = {
    id: siteId,
  };
  const resultSiteNodes = siteNodesApi.endpoints.getSiteNodes.useQuery(
    nodesPayload,
    {
      skip: siteId === "",
    }
  );
  const siteNodes =
    resultSiteNodes.data !== undefined ? resultSiteNodes.data.result : [];
  const activeSiteNodes = siteNodes.filter((node) => node.active);
  const resultSiteImage = siteImageApi.endpoints.getSiteImage.useQuery(siteId, {
    skip: !requireDroneImage,
  });

  const hasDroneImage =
    resultSiteImage.data !== undefined &&
    Object.keys(resultSiteImage.data.imageDetails).length !== 0;

  const currentUser = useGetCurrentUserQuery(null);
  let isAdmin = false;
  if (currentUser.data !== undefined) {
    isAdmin = currentUser.data.isAdmin;
  }

  const singleNodeSitePAQSPayload = {
    siteId,
    startDate: startTimestampPPM,
    endDate: endTimestampPPM,
    timezone: showLocalTimeForMapPPM ? timezone : "UTC",
  };
  const ppmForSiteMapParams = {
    siteId,
    startDate: startTimestampPPM,
    timezone: showLocalTimeForMapPPM ? timezone : "UTC",
    ppmEndDateHours,
  };
  const ppmForSiteMapResponse =
    ppmForSiteMapApi.endpoints.getPPMForSiteMap.useQuery(ppmForSiteMapParams, {
      skip: siteId === "" || hourBeforeEndTimestampPPM === "",
    });

  const ppmForSiteMap =
    ppmForSiteMapResponse.data !== undefined
      ? ppmForSiteMapResponse.data.result
      : [];
  const singleNodeSitePAQSResponse =
    singleNodeSitePAQSApi.endpoints.getSingleNodeSitePAQS.useQuery(
      singleNodeSitePAQSPayload,
      { skip: siteId === "" }
    );
  const singleNodeSitePAQS = singleNodeSitePAQSResponse?.data?.result ?? [];

  const siteGrid = isMultipleGrid
    ? siteGridsResult.data
    : siteGridConfigResult.data;
  const isSiteGridLoading =
    siteGridConfigResult.isFetching || siteGridsResult.isFetching;

  const [editSiteGrid, setEditSiteGrid] = useState(
    siteGrid !== undefined ? JSON.parse(JSON.stringify(siteGrid)) : {}
  );
  const editSiteGridRef = useRef(null);

  const setCurrentSelectedPointCB = (selectedPoint, location) => {
    if (selectedPoint === 1) {
      setPoint1(location);
    } else {
      setPoint2(location);
    }
  };

  const [createSiteGridsTrigger] = useCreateSiteGridsMutation();

  const onSaveGridConfig = (newGrid) => {
    if (isMultipleGrid) {
      createSiteGridsTrigger({ grids: editSiteGridRef.current, siteId })
        .unwrap()
        .then((result) => {
          window.alert("Site Grid Successfully Updated");
          console.log(result);
        })
        .catch((e) => console.log(e));
    } else {
      if (window.cellMatrix === undefined || window.cellMatrix.length === 0) {
        window.alert("No Site Grid has been configured. Please try again");
        return;
      }
      const timestamp = newGrid
        ? generateTimestamp()
        : window.siteGrid.Timestamp;
      createSiteGridCofigTrigger({
        ...window.siteGrid,
        SiteId: siteId,
        Timestamp: timestamp,
      })
        .unwrap()
        .then((e) => {
          if (e.Message === "SUCCESS") {
            window.alert("Site Grid Successfully Updated");
          } else {
            window.alert(
              "Site Grid Update Failed, please contact Earthview team or try again"
            );
          }
        })
        .catch((e) => {
          window.alert(
            "Site Grid Update Failed, please contact Earthview team or try again"
          );
        });
    }
  };

  const onCleanDrawGrid = () => {
    cleanGridDrawing(window.map, window.Microsoft.Maps);
  };

  const onToggleDroneImageLayer = (newValue) => {
    if (hasDroneImage && requireDroneImage) {
      setShowDroneImageLayer(newValue);
      handleToggleDroneImageLayer(newValue, resultSiteImage.data);
      if (
        newValue &&
        (showSiteGrid || showActiveCells || selectedAlarmIncident)
      ) {
        removeDroneLayersGrid();
        applyMapLayersForDrone(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          null,
          showSiteGrid,
          showActiveCells,
          additionalHighlightedCells
        );
      }
      if (showWinds && selectedPpmData) {
        endAllAnimations(1e6);
        handleToggleWindsLayer(
          window.map,
          window.Microsoft.Maps,
          true,
          drawCallback
        );
      }
    } else {
      window.alert(
        "Please contact support to enable this feature info@earthview.io"
      );
    }
  };

  const onToggleNodesLayer = (newValue) => {
    setShowNodesLayer(newValue);
    handleToggleNodesLayer(
      window.map,
      window.Microsoft.Maps,
      newValue,
      activeSiteNodes,
      selectedPpmData,
      showCaughtEventsLayer,
      caughtEventsPushPins,
      showVOCLevels
    );
  };

  const onToggleGridLayer = (newValue) => {
    setShowSiteGrid(newValue);
    window.showSiteGrid = newValue;
    if (siteGrid === undefined || Object.keys(siteGrid).length === 0) {
      window.alert("No Site Grid configured for current site");
    } else {
      const onClickCallback = selectIncidentActualLeakSource
        ? (e) => setIncidentActualLeakSourceOnMap(e.target.cellId)
        : null;
      if (requireDroneImage) {
        applyMapLayersForDrone(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          onClickCallback,
          newValue,
          showActiveCells,
          additionalHighlightedCells
        );
      } else {
        applyMapLayers(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          onClickCallback,
          newValue,
          showActiveCells,
          additionalHighlightedCells,
          isMultipleGrid
        );
      }
    }
  };

  const onToggleActiveGridCells = (newValue) => {
    setShowActiveCells(newValue);
    window.showActiveCells = newValue;
    if (siteGrid === undefined || Object.keys(siteGrid).length === 0) {
      window.alert("No Site Grid configured for current site");
    } else {
      const onClickCallback = selectIncidentActualLeakSource
        ? (e) => setIncidentActualLeakSourceOnMap(e.target.cellId)
        : null;
      if (requireDroneImage) {
        applyMapLayersForDrone(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          onClickCallback,
          showSiteGrid,
          newValue,
          additionalHighlightedCells
        );
      } else {
        applyMapLayers(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          onClickCallback,
          showSiteGrid,
          newValue,
          additionalHighlightedCells,
          isMultipleGrid
        );
      }
    }
  };

  const onToggleGPAQSLayer = (newValue) => {
    toggleGPAQSLayerHelper(
      newValue,
      setShowGPAQSLayer,
      showSiteGrid,
      onToggleGridLayer,
      isPpmToggled,
      onTogglePPMLayer,
      showWinds,
      onToggleWindsLayer,
      showActiveCells,
      onToggleActiveGridCells,
      showNodesLayer,
      onToggleNodesLayer,
      siteGrid,
      singleNodeSitePAQS,
      selectedPpmData,
      setSelectedGPAQSLargestValue,
      setAdditionalHighlightedCells,
      requireDroneImage
    );
  };

  const handleEditTypeChange = (newEditType) => {
    setSelectedEditType(newEditType);
    setSelectedCellToEdit(null);
    onEditGrid(newEditType);
  };

  const onEditGrid = (newEditType) => {
    handleToggleNodesLayer(window.map, window.Microsoft.Maps, false, {}, {});
    setShowSiteGrid(true);
    setShowActiveCells(true);
    setAdditionalHighlightedCells([]);
    if (isMultipleGrid) {
      editSiteGridRef.current = siteGrid;
      const onClickCallback = (e) => {
        newEditType === 1
          ? onEditToggleActiveCells(e)
          : onSetSelectedCellToEdit(e, formKey);
      };
      applyMapLayersForDroneWithVirtualGrid(
        window.map,
        window.Microsoft.Maps,
        editSiteGridRef.current,
        onClickCallback,
        true,
        true,
        [],
        requireDroneImage,
        isMultipleGrid
      );
    } else {
      const newEditSiteGrid =
        siteGrid !== undefined ? JSON.parse(JSON.stringify(siteGrid)) : {};
      editSiteGridRef.current = newEditSiteGrid;
      setSelectedCellToEdit(null);
      setEditSiteGrid(newEditSiteGrid);
      window.showSiteGrid = true;
      window.showActiveCells = true;
      if (siteGrid === undefined || Object.keys(siteGrid).length === 0) {
        window.alert("No Site Grid configured for current site");
      } else {
        const onClickCallback = (e) => {
          newEditType === 1
            ? onEditToggleActiveCells(e)
            : onSetSelectedCellToEdit(e, formKey);
        };
        applyMapLayersForDroneWithVirtualGrid(
          window.map,
          window.Microsoft.Maps,
          editSiteGridRef.current,
          onClickCallback,
          true,
          true,
          [],
          requireDroneImage,
          isMultipleGrid
        );
      }
    }
  };

  const onSelectViewMap = () => {
    setShowSiteGrid(false);
    setShowActiveCells(false);
    setShowNodesLayer(true);
    onToggleWindsLayer(true);
    onTogglePPMLayer(true);
    fetchSiteGridConfig();
  };

  const onSelectAlarmIncident = async (alarmIncident) => {
    if (showCaughtEventsLayer) {
      toggleCaughtEventsLayer(false);
    }
    if (selectedAlarmIncident) {
      onClearSelectedAlarm();
    }
    if (isPPMPlaying) {
      onStopPPM();
    }
    const startTime =
      alarmIncident.type === "highConcentrationAlarm"
        ? alarmIncident.Timestamp
        : alarmIncident?.most_likely_event?.Timestamp ||
          alarmIncident.Timestamp;

    const alarmDate = convertTimezone(startTime, "UTC", timezone);

    const alarmStartDateObj = new Date(alarmDate);
    const alarmStartTimestamp = formatDate(alarmStartDateObj);
    handleChangeTimestampPPM(alarmStartTimestamp);

    if (!isPpmToggled) setIsPpmToggled(true);
    if (!showWinds) setShowWinds(true);

    setSelectedAlarmIncident(alarmIncident);
    setShowIncidentPanel(true);
    setSelectIncidentActualSource(false);

    const mostLikelyEvent = alarmIncident.most_likely_event
      ? alarmIncident.most_likely_event
      : null;
    const mostLikelyEvent2 = alarmIncident.most_likely_event_II
      ? alarmIncident.most_likely_event_II
      : null;

    const likelySource1Color = requireDroneImage
      ? "rgba(255, 77, 79, 0.49)"
      : new window.Microsoft.Maps.Color(125, 255, 77, 79);

    const likelySource2Color = requireDroneImage
      ? "rgba(250, 140, 22, 0.49)"
      : new window.Microsoft.Maps.Color(125, 250, 140, 22);

    const actualLeakSourceFillColor = requireDroneImage
      ? "rgba(250, 200, 22, 0.49)"
      : new window.Microsoft.Maps.Color(125, 250, 200, 22);

    const likelyLeakLocation = mostLikelyEvent
      ? mostLikelyEvent.likelyLeakLocation
      : null;
    const likelyLeakLocation2 = mostLikelyEvent2
      ? mostLikelyEvent2.likelyLeakLocation
      : null;

    const _additionalHighlightedCells = [];

    if (likelyLeakLocation) {
      _additionalHighlightedCells.push({
        cellId: likelyLeakLocation,
        fillColor: likelySource1Color,
      });
    }

    if (likelyLeakLocation2) {
      _additionalHighlightedCells.push({
        cellId: likelyLeakLocation2,
        fillColor: likelySource2Color,
      });
    }

    if (alarmIncident.recalculatedAverageEmissionsMCF) {
      setAlarmBackcalculation(alarmIncident.recalculatedAverageEmissionsMCF);
    } else {
      setAlarmBackcalculation(null);
    }

    if (alarmIncident.incidentNotes) {
      setIncidentNotes(alarmIncident.incidentNotes);
    } else {
      setIncidentNotes("");
    }

    if (alarmIncident.incidentType) {
      setIncidentType(alarmIncident.incidentType);
    } else {
      setIncidentType("fugitive");
    }

    if (alarmIncident.incidentActualLeakSource) {
      setIncidentActualLeakSource(alarmIncident.incidentActualLeakSource);
      _additionalHighlightedCells.push({
        cellId: alarmIncident.incidentActualLeakSource,
        fillColor: actualLeakSourceFillColor,
      });
    } else {
      setIncidentActualLeakSource("");
    }

    setAdditionalHighlightedCells(_additionalHighlightedCells);

    if (
      siteGrid === undefined ||
      (alarmIncident.grid_timestamp !== undefined &&
        alarmIncident.grid_timestamp !== siteGrid.Timestamp)
    ) {
      await getSiteGridConfigTrigger({
        siteId,
        timestamp: alarmIncident.grid_timestamp,
      })
        .unwrap()
        .then((res) => {
          if (requireDroneImage) {
            applyMapLayersForDrone(
              window.map,
              window.Microsoft.Maps,
              res,
              null,
              showSiteGrid,
              showActiveCells,
              _additionalHighlightedCells
            );
          } else {
            applyMapLayers(
              window.map,
              window.Microsoft.Maps,
              res,
              null,
              showSiteGrid,
              showActiveCells,
              _additionalHighlightedCells
            );
          }
        });
    } else {
      if (requireDroneImage) {
        removeGrid(window.map, window.Microsoft.Maps);
        applyMapLayersForDrone(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          null,
          showSiteGrid,
          showActiveCells,
          _additionalHighlightedCells
        );
      } else {
        applyMapLayers(
          window.map,
          window.Microsoft.Maps,
          siteGrid,
          null,
          showSiteGrid,
          showActiveCells,
          _additionalHighlightedCells
        );
      }
    }
  };

  const onSaveEditCell = (formValues) => {
    const keys = Object.keys(formValues);
    const filteredKeys = keys.filter((key) => key.startsWith("equipment"));
    const equipment = filteredKeys.map((x) => formValues[x]);
    const selectedCellCopy = {
      isActive: selectedCellToEdit.isActive,
      name: formValues.name,
      height: formValues.height,
      equipment,
    };

    const cellCoordinatesKey = `(${selectedCellToEdit.x},${selectedCellToEdit.y})`;

    if (isMultipleGrid) {
      const gridId = selectedCellToEdit.gridId;
      const gridSetupCopy = [...editSiteGridRef.current];
      const gridIndex = gridSetupCopy.findIndex((g) => g.Timestamp === gridId);

      if (gridIndex !== -1) {
        const gridCopy = {
          ...gridSetupCopy[gridIndex],
          [selectedCellToEdit.cellId]: selectedCellCopy,
        };

        gridSetupCopy[gridIndex] = gridCopy;

        editSiteGridRef.current = gridSetupCopy;
        onSaveGridConfig();
      }
    } else {
      const siteGridCopy = { ...editSiteGrid };
      siteGridCopy[cellCoordinatesKey] = selectedCellCopy;

      setEditSiteGrid(siteGridCopy);

      window.siteGrid = siteGridCopy;
      editSiteGridRef.current = window.siteGrid;

      onSaveGridConfig();

      applyMapLayers(
        window.map,
        window.Microsoft.Maps,
        siteGridCopy,
        (e) => onSetSelectedCellToEdit(e, formKey),
        true,
        true,
        additionalHighlightedCells
      );
    }
  };

  const onSetSelectedCellToEdit = (e, thisFormKey) => {
    const Maps = window.Microsoft.Maps;
    const fillColor = requireDroneImage
      ? "rgba(125,250,200,0.0863)"
      : new Maps.Color(125, 250, 200, 22);
    const clickedEntity = e.target;
    const currentEditSiteGrid = editSiteGridRef.current;
    if (isMultipleGrid) {
      const gridSetupCopy = [...currentEditSiteGrid];
      const selectedGrid = gridSetupCopy.find(
        (g) => g.Timestamp === clickedEntity.gridId
      );
      const gridCopy = { ...selectedGrid };
      const cellCopy = { ...gridCopy[clickedEntity.cellId] };
      if (!cellCopy.name) cellCopy.name = "";
      if (!cellCopy.equipment) cellCopy.equipment = [""];
      cellCopy.cellId = clickedEntity.cellId;
      cellCopy.gridId = clickedEntity.gridId;
      setSelectedCellToEdit(cellCopy);
      const newFormKey = thisFormKey + 1;
      setFormKey(newFormKey);
      const highlightedCells = [
        {
          cellId: clickedEntity.cellId,
          fillColor,
          gridId: clickedEntity.gridId,
        },
      ];
      setAdditionalHighlightedCells(highlightedCells);
      applyMapLayersForDroneWithVirtualGrid(
        window.map,
        window.Microsoft.Maps,
        editSiteGridRef.current,
        (e) => onSetSelectedCellToEdit(e, newFormKey),
        true,
        true,
        highlightedCells,
        requireDroneImage,
        isMultipleGrid
      );
    } else {
      const gMatrix = getCellMatrix(currentEditSiteGrid);
      const selectedCellIndex = gMatrix.findIndex((element) => {
        return element.x === clickedEntity.x && element.y === clickedEntity.y;
      });
      const selectedCellCopy = {
        ...gMatrix[selectedCellIndex],
      };

      const cellMatrixCopy = [...gMatrix];
      cellMatrixCopy[selectedCellIndex] = selectedCellCopy;
      window.cellMatrix = cellMatrixCopy;

      setSelectedCellToEdit(selectedCellCopy);
      // this will cause the form to re-render in the Edit action
      const newFormKey = thisFormKey + 1;
      setFormKey(newFormKey);

      const highlightedCells = [{ cellId: clickedEntity.cellId, fillColor }];
      setAdditionalHighlightedCells(highlightedCells);
      applyMapLayersForDroneWithVirtualGrid(
        window.map,
        window.Microsoft.Maps,
        editSiteGridRef.current,
        (e) => onSetSelectedCellToEdit(e, newFormKey),
        window.showSiteGrid,
        window.showActiveCells,
        highlightedCells,
        requireDroneImage,
        isMultipleGrid
      );
    }
  };

  const onEditToggleActiveCells = (e) => {
    if (isMultipleGrid) {
      const gridId = e.target.gridId;
      const currentGridSetup = editSiteGridRef.current;
      const currentGrid = currentGridSetup.find((g) => g.Timestamp === gridId);
      const newValue = !e.target.isActive;
      const newGrid = { ...currentGrid };
      newGrid[e.target.cellId] = {
        ...newGrid[e.target.cellId],
        isActive: newValue,
      };
      const newGridSetup = currentGridSetup.map((grid) =>
        grid.Timestamp === gridId ? newGrid : grid
      );
      editSiteGridRef.current = newGridSetup;
      const inactiveColor = new window.Microsoft.Maps.Color(100, 140, 140, 140);
      const activeColor = new window.Microsoft.Maps.Color(100, 82, 196, 26);
      e.target.isActive = newValue;
      if (newValue) {
        e.target.setOptions({ fillColor: activeColor });
      } else {
        e.target.setOptions({ fillColor: inactiveColor });
      }
    } else {
      const clickedEntity = e.target;
      const currentEditSiteGrid = editSiteGridRef.current;
      const gMatrix = getCellMatrix(currentEditSiteGrid);
      const selectedCellIndex = gMatrix.findIndex((element) => {
        return element.x === clickedEntity.x && element.y === clickedEntity.y;
      });

      const selectedCellCopy = {
        ...gMatrix[selectedCellIndex],
        isActive: !gMatrix[selectedCellIndex].isActive,
      };

      const cellMatrixCopy = [...gMatrix];

      cellMatrixCopy[selectedCellIndex] = selectedCellCopy;

      window.cellMatrix = cellMatrixCopy;

      const siteGridCopy = { ...currentEditSiteGrid };

      const cellCoordinatesKey = `(${clickedEntity.x},${clickedEntity.y})`;

      siteGridCopy[cellCoordinatesKey].isActive = selectedCellCopy.isActive;

      setEditSiteGrid(siteGridCopy);

      window.siteGrid = siteGridCopy;
      applyMapLayersForDroneWithVirtualGrid(
        window.map,
        window.Microsoft.Maps,
        editSiteGridRef.current,
        (e) => onEditToggleActiveCells(e),
        window.showSiteGrid,
        window.showActiveCells,
        [],
        requireDroneImage,
        isMultipleGrid
      );
    }
  };

  const setIncidentActualLeakSourceOnMap = (cellId) => {
    setIncidentActualLeakSource(cellId);
    const Maps = window.Microsoft.Maps;
    let _highlightedCells = [];

    if (!newIncident) {
      _highlightedCells = [{ ...additionalHighlightedCells[0] }];
    }

    if (
      !newIncident &&
      selectedAlarmIncident &&
      selectedAlarmIncident.most_likely_event_II
    ) {
      _highlightedCells.push({ ...additionalHighlightedCells[1] });
    }

    const fillColor = requireDroneImage
      ? "rgba(250, 200, 22, 125)"
      : new Maps.Color(125, 250, 200, 22);

    _highlightedCells.push({
      cellId,
      fillColor,
    });

    setAdditionalHighlightedCells(_highlightedCells);
    applyMapLayersForDroneWithVirtualGrid(
      window.map,
      window.Microsoft.Maps,
      siteGrid,
      (e) => setIncidentActualLeakSourceOnMap(e.target.cellId),
      window.showSiteGrid,
      window.showActiveCells,
      _highlightedCells,
      requireDroneImage,
      isMultipleGrid
    );
  };

  const initSetIncidentActualLeakSource = (newValue) => {
    setSelectIncidentActualSource(newValue);
    const onClickCallback = newValue
      ? (e) => setIncidentActualLeakSourceOnMap(e.target.cellId)
      : null;
    applyMapLayersForDroneWithVirtualGrid(
      window.map,
      window.Microsoft.Maps,
      siteGrid,
      onClickCallback,
      showSiteGrid,
      showActiveCells,
      additionalHighlightedCells,
      requireDroneImage,
      isMultipleGrid
    );
  };

  const onClearSelectedAlarm = () => {
    setAdditionalHighlightedCells([]);
    setShowIncidentPanel(false);
    setSelectedAlarmIncident(null);
    setIncidentActualLeakSource("");
    setSelectedAlarmIncident(false);
    setSelectIncidentActualSource(false);

    if (requireDroneImage) {
      applyMapLayersForDrone(
        window.map,
        window.Microsoft.Maps,
        siteGrid,
        null,
        showSiteGrid,
        showActiveCells,
        []
      );
    } else {
      applyMapLayers(
        window.map,
        window.Microsoft.Maps,
        siteGrid,
        null,
        showSiteGrid,
        showActiveCells,
        []
      );
    }
  };

  const onSaveAlarmIncident = () => {
    const actualLeakSource =
      incidentActualLeakSource == "" ? "N/A" : incidentActualLeakSource;
    const updatedAlarmIncident = {
      ...selectedAlarmIncident,
      incidentType,
      incidentNotes,
      incidentActualLeakSource: actualLeakSource,
      status: "Classified",
    };

    if (alarmBackcalculation !== null) {
      updatedAlarmIncident.recalculatedAverageEmissionsMCF =
        alarmBackcalculation;
    }

    if (!incidentNotes) {
      window.alert("Missing incident notes.");
      return;
    }

    updateGpaqsAlarmIncidentMutation(updatedAlarmIncident)
      .unwrap()
      .then((response) => {
        if (response.Message === "SUCCESS") {
          onSelectAlarmIncident(updatedAlarmIncident);
          if (requireDroneImage) {
            removeGrid(window.map, window.Microsoft.Maps);
          }
          window.alert("Alarm Successfully Updated");
        }
        if (currentUser.data !== undefined) {
          const orgIds = currentUser.data.organizations.map((x) =>
            String(x.OrgId)
          );
          const params = {
            ids: String(orgIds?.join(",")),
          };
          customerAlarmIncidentsTrigger(params)
            .unwrap()
            .then((res) => {
              const sideBarElements = [];
              for (const org of res) {
                for (const element of org.sites) {
                  sideBarElements.push({
                    site: element,
                    title: element.name,
                    subtitle: org.country,
                    onClick: "selectSite",
                    id: element.SiteId,
                    description: "",
                    organization: {
                      OrgId: org.OrgId,
                      name: org.orgName,
                      country: org.country,
                    },
                    isActive: element.active,
                  });
                }
              }
              const sorted = sideBarElements.sort((a, b) => {
                const aIsActive = a.isActive !== null && a.isActive === true;
                const bIsActive = b.isActive !== null && b.isActive === true;
                if (aIsActive && !bIsActive) return -1;
                if (!aIsActive && bIsActive) return 1;

                return a.title.localeCompare(b.title);
              });

              dispatch(setSideBarElements(sorted));
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const selectedAlarmId =
    selectedAlarmIncident !== null
      ? `${selectedAlarmIncident.Timestamp}-${selectedAlarmIncident.type}`
      : "";

  const fetchSiteAlarmIncidents = async () => {
    await triggerGpaqsAlarmIncidentsQuery(gpaqsAlarmIncidentsPayload);
  };

  const onTogglePPMLayer = (newValue) => {
    if (newValue !== undefined) {
      setIsPpmToggled(newValue);
    } else {
      setIsPpmToggled((lastToggle) => !lastToggle);
    }
  };

  const onDateChangePPM = (date, dateString) => {
    if (isPPMPlaying) {
      onStopPPM();
    }

    const newstartTimestamp = dateString + " 00:00:00";
    const newEndTimestamp = getNewEndTimestamp(newstartTimestamp, 1, 0);
    handleChangeTimestampPPM(newstartTimestamp);
    dispatch(setStartTimestamp(newstartTimestamp));
    dispatch(setEndTimestamp(newEndTimestamp));
  };

  const onTimeChangePPM = (time) => {
    if (isPPMPlaying) {
      onStopPPM();
    }
    const newTimeObj = new Date(time);
    const newTimeTimestamp = formatDate(newTimeObj);
    const [_, newTime] = newTimeTimestamp.split(" ");

    const newStartTimestamp = startDatePPM + " " + newTime;
    handleChangeTimestampPPM(newStartTimestamp);
  };

  const handleChangeShowLocalTimeForMapPPM = (newValue) => {
    setShowLocalTimeForMapPPM(newValue);
  };

  // start wind animations

  const drawCallback = (canvas) => {
    const ctx = canvas.getContext("2d");
    const map = window.map;
    const Maps = window.Microsoft.Maps;

    const zoom = map.getZoom();
    const scale = zoom * 0.9;

    // Get node pixel & wind data
    const locations = activeSiteNodes.map((node) => {
      return { latitude: node.latitude, longitude: node.longitude };
    });

    let nodePixelData = map.tryLocationToPixel(
      locations,
      Maps.PixelReference.control
    );

    nodePixelData = nodePixelData.filter((data) => {
      const goodX = Math.abs(data.x) < 10000;
      const goodY = Math.abs(data.y) < 10000;

      return goodX && goodY;
    });

    const windDataXY = selectedPpmData.reads.map((nodeRead) => {
      const { nodeId, windDIR, windMPH } = nodeRead;
      const [windMPHX, windMPHY] = polarToCartesian(windMPH, windDIR);
      return {
        nodeId,
        windMPHX,
        windMPHY,
      };
    });

    const nodeIds = activeSiteNodes.map((node) => node.NodeId);
    const nodeWinds = nodeIds.map((id) => {
      const data = windDataXY.find((ele) => ele.nodeId === id);
      if (data === undefined) {
        return [0, 0];
      }
      return [data.windMPHX, data.windMPHY];
    });
    const nodePixelWindData = nodePixelData.map((obj, idx) => {
      return {
        ...obj,
        NodeId: nodeIds[idx],
        xWind: nodeWinds[idx][0],
        yWind: nodeWinds[idx][1],
      };
    });

    // define square bouding nodes
    const nodeXPixels = nodePixelData.map((point) => point.x);
    const nodeMinXPixel = Math.min(...nodeXPixels);
    const nodeMaxXPixel = Math.max(...nodeXPixels);

    const nodeYPixels = nodePixelData.map((point) => point.y);
    const nodeMinYPixel = Math.min(...nodeYPixels);
    const nodeMaxYPixel = Math.max(...nodeYPixels);

    const originX =
      nodeMinXPixel + Math.abs((nodeMaxXPixel - nodeMinXPixel) / 2);
    const originY =
      nodeMinYPixel + Math.abs((nodeMaxYPixel - nodeMinYPixel) / 2);

    // add padding around nodes
    const pad = 70;
    let boundaryWidth = Math.abs(nodeMaxXPixel - nodeMinXPixel) + 2 * pad;
    let boundaryHeight = Math.abs(nodeMaxYPixel - nodeMinYPixel) + 2 * pad;

    if (zoom < 18) {
      boundaryWidth = boundaryWidth - 80;
      boundaryHeight = boundaryHeight - 80;
    }

    const minX = originX - boundaryWidth / 2;
    const maxX = originX + boundaryWidth / 2;
    const minY = originY - boundaryHeight / 2;
    const maxY = originY + boundaryHeight / 2;

    let arrows = [];
    const arrowCount = 200;
    const createArrow = () => {
      const x = minX + Math.random() * (maxX - minX);
      const y = minY + Math.random() * (maxY - minY);

      const arrow = {
        x,
        y,
        opacity: 1,
      };
      arrows.push(arrow);
    };

    const updateVectorPositions = () => {
      // Set the composite operation to destination-out
      ctx.globalCompositeOperation = "destination-out";

      // Reduce the opacity of existing content to create traces
      ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Reset the composite operation to default
      ctx.globalCompositeOperation = "source-over";

      // Loop through all the arrow objects
      arrows.forEach(function (arrow) {
        const [velocityX, velocityY] = calculateWindVelocity(
          nodePixelWindData,
          arrow.x,
          arrow.y,
          scale
        );
        const newX = arrow.x + velocityX;
        const newY = arrow.y + velocityY;

        // Check if the new position is within the boundary
        if (newX >= minX && newX <= maxX && newY >= minY && newY <= maxY) {
          arrow.x = newX;
          arrow.y = newY;
        } else {
          arrow.opacity = 0;
        }

        // Set the arrow's opacity
        ctx.globalAlpha = arrow.opacity;

        // Save the current canvas state
        ctx.save();

        // Translate to the arrow's position
        ctx.translate(arrow.x, arrow.y);

        // Draw the dot shape
        let radius = 2;
        if (zoom < 18) {
          radius = 0.7;
        }
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI);
        ctx.fillStyle = "white";
        ctx.fill();

        // Restore the canvas state
        ctx.restore();
      });

      // Reset the global opacity
      ctx.globalAlpha = 1;

      // Remove arrows with opacity <= 0 from the array
      arrows = arrows.filter(function (arrow) {
        return arrow.opacity > 0;
      });

      // Add new arrows to maintain the desired count
      while (arrows.length < arrowCount) {
        createArrow();
      }

      // Request the next frame to continue the animation
      const id = requestAnimationFrame(updateVectorPositions);
      animationIdRef.current = id;
    };

    // Create the initial arrows
    for (let i = 0; i < arrowCount; i++) {
      createArrow();
    }

    // Start the animation
    updateVectorPositions();
  };

  const endAllAnimations = (value = null) => {
    if (value === null) value = animationIdRef.current * 1.5;
    for (let i = 0; i <= value; i++) {
      cancelAnimationFrame(animationIdRef.current);
    }
  };

  const onHoverOverPpm = (value) => {
    if (isPPMPlaying) {
      onStopPPM();
    }
    if (showNodesLayer) {
      let nodePushPins = activeSiteNodes.map((node) => {
        return createNodePushPin(node, value, showVOCLevels);
      });
      if (showCaughtEventsLayer) {
        nodePushPins = [...nodePushPins, ...caughtEventsPushPins];
      }
      addNodePushpins(nodePushPins);
    }
    if (value !== selectedPpmData) {
      setSelectedPpmData(value);
      if (showGPAQSLayer) onToggleGPAQSLayer(true);
    }
  };

  const onToggleWindsLayer = (newValue) => {
    setShowWinds(newValue);
    if (newValue === false) {
      endAllAnimations(1e6);
      removeWindVector();
    }

    if (selectedPpmData !== null && newValue) {
      handleToggleWindsLayer(
        window.map,
        window.Microsoft.Maps,
        newValue,
        drawCallback,
      );
    }
  };

  const resetLayers = () => {
    if (requireDroneImage && hasDroneImage) {
      onToggleDroneImageLayer(true);
      removeDroneLayersGrid();
    }
    setPoint1(defaultPointObject);
    setPoint2(defaultPointObject);
    onToggleWindsLayer(false);
    setShowNodesLayer(false);
    setSelectedPpmData(null);
    setSelectedAlarmIncident(null);
    setIsPpmToggled(false);
    setShowAvgEmissions(false);
    setShowActiveCells(false);
    setShowSiteGrid(false);
    setShowGPAQSLayer(false);
    setShowIncidentPanel(false);
    setAdditionalHighlightedCells([]);
  };

  const triggerTooltip = (chart, index) => {
    if (
      chart.tooltip &&
      chart.tooltip.getActiveElements &&
      typeof chart.tooltip.getActiveElements === "function"
    ) {
      const tooltip = chart.tooltip;
      const chartArea = chart.chartArea;
      tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: index,
          },
        ],
        {
          x: (chartArea.left + chartArea.right) / 2,
          y: (chartArea.top + chartArea.bottom) / 2,
        }
      );
      chart.update();
    }
  };

  const onPlayPPM = (chartInstance) => {
    if (!isPPMPlaying) {
      setisPPMPlaying(true);
    }
    const processArrayWithDelay = (array, delay) => {
      let index = 0;

      const processNextItem = () => {
        if (index < array.length) {
          const value = array[index];
          setSelectedPpmData(value);
          if (showNodesLayer) {
            const nodePushPins = activeSiteNodes.map((node) => {
              return createNodePushPin(node, value, showVOCLevels);
            });

            addNodePushpins(nodePushPins);
          }
          triggerTooltip(chartInstance, index);
          index++;
          alarmPlayingRef.current = setTimeout(processNextItem, delay);
          setisPPMPlaying(true);
        } else {
          setisPPMPlaying(false);
        }
      };

      processNextItem();
    };
    if (!showWinds) setShowWinds(true);
    if (!showNodesLayer) {
      setShowNodesLayer(true);
      handleToggleNodesLayer(
        window.map,
        window.Microsoft.Maps,
        true,
        activeSiteNodes,
        selectedPpmData,
        showCaughtEventsLayer,
        caughtEventsPushPins
      );
    }
    if (ppmForSiteMap.length > 0) {
      const delayInSeconds = 1;
      processArrayWithDelay(ppmForSiteMap, delayInSeconds * 1000);
    }
  };

  const onStopPPM = () => {
    if (alarmPlayingRef.current) {
      clearTimeout(alarmPlayingRef.current);
      setisPPMPlaying(false);
    }
  };

  const fetchSiteGridConfig = async () => {
    if (isMultipleGrid) {
      await getSiteGridsTrigger({ siteId });
    } else {
      await getSiteGridConfigTrigger({ siteId });
    }
  };

  useEffect(() => {
    initializeMapVariables();
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      const userOrgs = currentUser.data?.organizations ?? [];
      if (userOrgs.length === 1) {
        const [org] = userOrgs;
        dispatch(setCurrentOrganization(org));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (siteId !== "") {
      setShowSiteGrid(false);
      setShowActiveCells(false);
      if (requireDroneImage && hasDroneImage) {
        onToggleDroneImageLayer(true);
      }
      onToggleWindsLayer(true);
      onTogglePPMLayer(true);
      fetchSiteGridConfig();
    }
  }, [siteId]);

  useEffect(() => {
    if (siteId !== "") {
      fetchSiteAlarmIncidents();
    }
  }, [startTimestamp, siteId]);

  useEffect(() => {
    const lastPpmForSiteMap = ppmForSiteMap.length
      ? ppmForSiteMap.slice(-1)[0]
      : null;
    if (lastPpmForSiteMap) setSelectedPpmData(lastPpmForSiteMap);
  }, [ppmForSiteMapResponse]);

  useEffect(() => {
    if (showWinds && selectedPpmData) onToggleWindsLayer(true);
  }, [selectedPpmData]);

  useEffect(() => {
    return () => {
      if (animationIdRef.current) endAllAnimations(1e6);
    };
  }, []);

  const onMakeMap = () => {
    makeMap(mapContainerRef, viewOptions);
  };

  useEffect(() => {
    if (currentSite === null) {
      if (showNodesLayer) {
        setShowNodesLayer(false);
        removePushPins();
      }
      if (showSiteGrid || showActiveCells || selectedAlarmIncident) {
        setShowSiteGrid(false);
        setShowActiveCells(false);
        setSelectedAlarmIncident(null);
        if (requireDroneImage) {
          removeDroneLayersGrid(window.map, window.Microsoft.Maps);
        } else {
          removeGrid(window.map, window.Microsoft.Maps);
        }
      }
      if (selectedPpmData) {
        setSelectedPpmData(null);
      }
      if (showWinds) {
        onToggleWindsLayer(false);
      }
      if (isPpmToggled) {
        setIsPpmToggled(false);
      }
      if (showAvgEmissions) {
        setShowAvgEmissions(false);
      }
      if (showIncidentPanel) {
        setShowIncidentPanel(false);
      }
      if (showMapController) {
        dispatch(setShowMapController(false));
      }
      if (showVOCLevels) {
        setShowVOCLevels(false);
      }
      if (showDroneImageLayer) {
        onToggleNodesLayer(false);
      }
    }
  }, [currentSite]);

  const onStartCreateNewEvent = () => {
    onClearSelectedAlarm();
    setNewIncident(true);
    setSelectedAlarmIncident(null);
    setShowIncidentPanel(true);
    setSelectIncidentActualSource(true);
    setShowSiteGrid(true);
    window.showSiteGrid = true;
    const onClickCallback = (e) =>
      setIncidentActualLeakSourceOnMap(e.target.cellId);
    applyMapLayers(
      window.map,
      window.Microsoft.Maps,
      siteGrid,
      onClickCallback,
      true,
      showActiveCells,
      additionalHighlightedCells
    );
  };

  const onCreateNewAlarmIncident = (newAlarmIncident) => {
    const endTimeString = newAlarmIncident.end_time.format(
      "YYYY-MM-DD HH:mm:00"
    );
    const startTimeString = newAlarmIncident.Timestamp.format(
      "YYYY-MM-DD HH:mm:00"
    );

    const endTimeToUTC = convertTimezone(endTimeString, timezone, "UTC");
    const startTimeToUTC = convertTimezone(startTimeString, timezone, "UTC");

    const newIncident = {
      ...newAlarmIncident,
      SiteId: siteId,
      grid_timestamp: siteGrid.Timestamp,
      status: "Classified",
      incidentActualLeakSource,
      end_time: endTimeToUTC,
      Timestamp: startTimeToUTC,
    };

    const parsedIncident = JSON.parse(JSON.stringify(newIncident));

    updateGpaqsAlarmIncidentMutation(parsedIncident)
      .unwrap()
      .then((response) => {
        if (response.Message === "SUCCESS") {
          window.alert("Alarm Successfully Created");
          onSelectAlarmIncident(newIncident);
        }
      })
      .catch((e) => console.log(e));
  };

  const [showCaughtEventsLayer, setShowCaughtEventsLayer] = useState(false);
  const [caughtEventsPushPins, setCaughtEventsPushPins] = useState([]);

  const toggleCaughtEventsLayer = (newValue) => {
    setShowCaughtEventsLayer(newValue);
    if (newValue) {
      if (selectedAlarmIncident) {
        onClearSelectedAlarm();
      }

      const eventsCells = siteGpaqsAlarmIncidents
        .filter((event) => event.status !== undefined)
        .map((alarmIncident) => {
          return alarmIncident.incidentActualLeakSource;
        });
      const pushPins = generatePushPins(siteGrid, eventsCells);
      setCaughtEventsPushPins(pushPins);
      if (showNodesLayer) {
        handleToggleNodesLayer(
          window.map,
          window.Microsoft.Maps,
          true,
          activeSiteNodes,
          selectedPpmData,
          true,
          pushPins
        );
      } else {
        addPushpins(pushPins);
      }
    } else {
      if (showNodesLayer) {
        handleToggleNodesLayer(
          window.map,
          window.Microsoft.Maps,
          showNodesLayer,
          activeSiteNodes,
          selectedPpmData,
          newValue,
          caughtEventsPushPins
        );
      } else {
        removePushPins();
      }
    }
  };

  useEffect(() => {
    if (siteNodes.length > 0) {
      onToggleNodesLayer(true);
    }
  }, [siteNodes]);

  useEffect(() => {
    if (showCaughtEventsLayer) {
      toggleCaughtEventsLayer(true);
    }
  }, [siteGpaqsAlarmIncidents]);

  useEffect(() => {
    if (_selectedAlarmIncident !== null) {
      onSelectAlarmIncident(_selectedAlarmIncident);
      dispatch(setCurrentAlarmIncident(null));
    }
  }, []);
  return (
    <div className={styles.OrgMap}>
      <MapController
        onSelectViewMap={onSelectViewMap}
        onToggleNodesLayer={onToggleNodesLayer}
        onToggleGridLayer={onToggleGridLayer}
        cleanDrawGrid={onCleanDrawGrid}
        onSaveGridConfig={onSaveGridConfig}
        isAdmin={isAdmin}
        onSelectAlarmIncident={onSelectAlarmIncident}
        setSelectedAlarmIncident={setSelectedAlarmIncident}
        onClearSelectedAlarm={onClearSelectedAlarm}
        selectedAlarmId={selectedAlarmId}
        onDateChange={onAlarmIncidentsDateChange}
        selectedDate={startTimestamp}
        alarmIncidents={siteGpaqsAlarmIncidents}
        fetchingAlarms={fetchingAlarms}
        onToggleActiveGridCells={onToggleActiveGridCells}
        timezone={timezone}
        onTogglePPMLayer={onTogglePPMLayer}
        isPpmToggled={isPpmToggled}
        setShowWinds={onToggleWindsLayer}
        resetLayers={resetLayers}
        onEditGrid={onEditGrid}
        selectedEditType={selectedEditType}
        handleEditTypeChange={handleEditTypeChange}
        selectedCellToEdit={selectedCellToEdit}
        formKey={formKey}
        onSaveEditCell={onSaveEditCell}
        showWinds={showWinds}
        showPPMLayer={isPpmToggled}
        currentSite={currentSite}
        showNodesLayer={showNodesLayer}
        showGridLayer={showSiteGrid}
        showActiveGridCellsLayer={showActiveCells}
        showSamplingRatesLayer={showSamplingRatesLayer}
        setShowSamplingRatesLayer={setShowSamplingRatesLayer}
        samplingRatesTarget={samplingRatesTarget}
        setSamplingRatesTarget={setSamplingRatesTarget}
        siteNodes={activeSiteNodes}
        siteGrid={siteGrid}
        showGPAQSLayer={showGPAQSLayer}
        onToggleGPAQSLayer={onToggleGPAQSLayer}
        selectedGPAQSLargestValue={selectedGPAQSLargestValue}
        onStartCreateNewEvent={onStartCreateNewEvent}
        toggleCaughtEventsLayer={toggleCaughtEventsLayer}
        showCaughtEventsLayer={showCaughtEventsLayer}
        isSiteGridLoading={isSiteGridLoading}
        showAvgEmissions={showAvgEmissions}
        onToggleAvgEmissions={onToggleAvgEmissions}
        showVOCLevels={showVOCLevels}
        setShowVOCLevels={setShowVOCLevels}
        showDroneImageLayer={showDroneImageLayer}
        setShowDroneImageLayer={onToggleDroneImageLayer}
        isMultipleGrid={isMultipleGrid}
        requireDroneImage={requireDroneImage}
      />

      <AlarmIncidentPanel
        setShowIncidentPanel={setShowIncidentPanel}
        selectedAlarmIncident={selectedAlarmIncident}
        initSetIncidentActualLeakSource={initSetIncidentActualLeakSource}
        incidentType={incidentType}
        setIncidentType={setIncidentType}
        incidentNotes={incidentNotes}
        setIncidentNotes={setIncidentNotes}
        onSaveAlarmIncident={onSaveAlarmIncident}
        timezone={timezone}
        incidentActualLeakSource={incidentActualLeakSource}
        selectIncidentActualLeakSource={selectIncidentActualLeakSource}
        showIncidentPanel={showIncidentPanel}
        showPPMLayer={isPpmToggled}
        onCreateNewAlarmIncident={onCreateNewAlarmIncident}
        siteNodes={siteNodes}
        siteGrid={siteGrid}
        setAlarmBackcalculation={setAlarmBackcalculation}
        recalculatedAverageEmissionsMCF={alarmBackcalculation}
        isAdmin={isAdmin}
      />
      <PPMPanel
        ppmEndDateHours={ppmEndDateHours}
        setPPMEndDateHours={setPPMEndDateHours}
        ppmForSiteMap={ppmForSiteMap}
        singleNodeSitePAQS={singleNodeSitePAQS}
        onDateChange={onDateChangePPM}
        startDate={startDatePPM}
        onTimeChange={onTimeChangePPM}
        startTime={startTimePPM}
        onHoverOverPpm={onHoverOverPpm}
        onPlayPPM={onPlayPPM}
        onStopPPM={onStopPPM}
        isPPMPlaying={isPPMPlaying}
        showPPMLayer={isPpmToggled}
        showAvgEmissions={showAvgEmissions}
        showLocalTimeForMapPPM={showLocalTimeForMapPPM}
        handleChangeShowLocalTimeForMapPPM={handleChangeShowLocalTimeForMapPPM}
        timezone={timezone}
        showVOCLevels={showVOCLevels}
      />
      <SideNavigation
        isAdmin={isAdmin}
        showPPMLayer={isPpmToggled}
        point1={point1}
        setCurrentSelectedPointCB={setCurrentSelectedPointCB}
      />
      <BingMapRenderer makeMap={onMakeMap} mapContainerRef={mapContainerRef} />
    </div>
  );
};

export default OrganizationMap;
