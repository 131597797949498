import { FC } from "react";
import { Form, Input, Button, DatePicker, Select } from "antd";
import { useCreateFieldRepairScheduleMutation } from "../../../store/fieldRepairSchedules/api";
import { RepairFormValues, ScheduleRepairProps } from "./types";
import styles from "./styles.module.scss";

const { TextArea } = Input;
const { Option } = Select;

const ScheduleRepair: FC<ScheduleRepairProps> = ({
  macAddress,
  closeModal,
}) => {
  const [form] = Form.useForm<RepairFormValues>();
  const [triggerCreateFieldRepairSchedule] =
    useCreateFieldRepairScheduleMutation();

  const handleFinish = async (values: RepairFormValues): Promise<void> => {
    const payload = {
      ...values,
      macAddress,
      repairDate: values.repairDate.format("YYYY-MM-DD"),
      isActive: true,
    };
    await triggerCreateFieldRepairSchedule(payload)
      .unwrap()
      .then((res) => {
        if (res.Message === "SUCCESS") {
          window.alert("Field repair successfully scheduled");
          form.resetFields();
          closeModal();
        } else {
          window.alert(
            "Error while saving the repair schedule. Please try again later"
          );
        }
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      className={styles.FieldRepairScheduleForm}
    >
      <Form.Item
        name="repairDate"
        label="Scheduled Repair Date"
        rules={[{ required: true, message: "Please select a date!" }]}
      >
        <DatePicker className={styles.datePicker} />
      </Form.Item>

      <Form.Item
        name="issueType"
        label="Issue Type"
        rules={[{ required: true, message: "Please select an issue type!" }]}
      >
        <Select placeholder="Select an issue type">
          <Option value="battery">Battery</Option>
          <Option value="cell">Cell</Option>
          <Option value="waterDamage">Water Damage</Option>
          <Option value="solarCharger">Solar Charger</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      <Form.Item name="notes" label="Notes" rules={[{ required: false }]}>
        <TextArea rows={4} placeholder="Enter any additional notes..." />
      </Form.Item>

      <Form.Item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Button
          type="default"
          onClick={closeModal}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ScheduleRepair;
