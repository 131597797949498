import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { DailyEmissionParams, DailyEmissionsResponse, TotalEmissionsByOrganizationResult } from "./types";

export const dailyEmissionsApi = createApi({
  reducerPath: "dailyEmissionsApi",
  baseQuery,
  tagTypes: ["DailyEmissions", "OrganizationEmissions"],
  endpoints: (builder) => ({
    getDailyEmissions: builder.query<
    DailyEmissionsResponse,
    DailyEmissionParams
    >({
      query: (payload) => {
        const { id, startDate, endDate } = payload;
        const params = {
          startDate,
          endDate,
        };
        return {
          url: `site/${id}/dailyEmissions`,
          method: "GET",
          params,
        };
      },
      providesTags: ["DailyEmissions"],
    }),
    getTotalEmissionsByOrganization: builder.query<
    TotalEmissionsByOrganizationResult,
    DailyEmissionParams
    >({
      query: (payload) => {
        const { id, startDate, endDate } = payload;
        const params = {
          startDate,
          endDate,
        };
        return {
          url: `organization/${id}/dailyEmissions`,
          method: "GET",
          params,
        };
      },
      providesTags: ["OrganizationEmissions"],
    }),
  }),
});

export const {
  useGetDailyEmissionsQuery,
  useLazyGetTotalEmissionsByOrganizationQuery,
} = dailyEmissionsApi;
