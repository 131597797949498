import { FC, useState } from "react";
import { Select, DatePicker, Button, Form, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  addPushpins,
  removePushPins,
} from "../../../pages/OrganizationMap/mapApi";
import { useLazyGetSamplingRatesQuery } from "../../../store/samplingRates/api";
import { getMapsColor } from "../../../pages/SiteMapv2/mapApi";
import { GridVisibilityControlsProps, Color, colorRates } from "./types";
import styles from "./styles.module.scss";
import { siteGridConfig } from "../../../store/gridConfig/api";
import { applyMapLayersGridVisibility } from "./gridVisibilityHelper";
import { LayersSwitch } from "../../LayersSwitch/LayersSwitch";

const GridVisibilityControls: FC<GridVisibilityControlsProps> = ({
  siteNodes,
  siteGrid,
  requireDroneImage,
  showDroneImage,
  setShowDroneImageLayer,
}) => {
  const [form] = Form.useForm();
  const [visibilityTarget, setVisibilityTarget] = useState<string>("");
  const [targetDate, setTargetDate] = useState<string | string[] | undefined>(
    undefined
  );
  const [getSamplingRatesQuery] = useLazyGetSamplingRatesQuery();
  const [getSiteGridConfigTrigger] =
    siteGridConfig.endpoints.getSiteGridConfig.useLazyQuery();

  const getCellColor = (value: number): Color | string => {
    if (value >= 0 && value <= 25) {
      return requireDroneImage
        ? "rgba(150,255,0,0.8)"
        : getMapsColor(150, 255, 0, 0.8);
    } else if (value > 25 && value <= 50) {
      return requireDroneImage
        ? "rgba(150,255,165,0.8)"
        : getMapsColor(150, 255, 165, 0.8);
    } else if (value > 50 && value <= 75) {
      return requireDroneImage
        ? "rgba(150,0,255,0.8)"
        : getMapsColor(150, 0, 255, 0.8);
    } else {
      return requireDroneImage
        ? "rgba(150,0,0,255)"
        : getMapsColor(150, 0, 0, 255);
    }
  };

  const onGenerateLayer = (): void => {
    form
      .validateFields()
      .then(async (samplingRates) => {
        if (targetDate !== undefined) {
          await getSamplingRatesQuery({
            id: visibilityTarget,
            date: targetDate as string,
            queryType: visibilityTarget === siteGrid.SiteId ? "site" : "node",
          })
            .unwrap()
            .then(async (samplingRates) => {
              if (samplingRates?.results !== undefined) {
                const additionalHighlightedCells = samplingRates.results.map(
                  (cell) => ({
                    cellId: cell.cellId,
                    fillColor: getCellColor(parseFloat(cell.percentage)),
                  })
                );
                const node = siteNodes.find(
                  (element) => element.NodeId === visibilityTarget
                );
                if (node !== undefined) {
                  addPushpins([
                    {
                      center: {
                        latitude: node.latitude,
                        longitude: node.longitude,
                      },
                      options: {
                        title: node.name,
                      },
                    },
                  ]);
                } else {
                  removePushPins();
                }

                if (
                  samplingRates.grid_timestamp !== undefined &&
                  samplingRates.grid_timestamp !== siteGrid.Timestamp
                ) {
                  const siteId = siteGrid.SiteId;
                  await getSiteGridConfigTrigger({
                    siteId,
                    timestamp: samplingRates.grid_timestamp,
                  })
                    .unwrap()
                    .then((res) => {
                      applyMapLayersGridVisibility(
                        res,
                        additionalHighlightedCells,
                        requireDroneImage
                      );
                    });
                } else {
                  applyMapLayersGridVisibility(
                    siteGrid,
                    additionalHighlightedCells,
                    requireDroneImage
                  );
                }
              }
            });
        } else {
          void message.error(
            "Please select a node and a date before applying."
          );
        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
        void message.error("Please select a node and a date before applying.");
      });
  };

  const disabledDate = (current: Dayjs): boolean => {
    const day = current.date();
    return !(day === 1 || day === 15);
  };

  return (
    <div className={styles.GridVisibility}>
      {requireDroneImage && (
        <div>
          {LayersSwitch(
            "Drone Image",
            setShowDroneImageLayer,
            showDroneImage,
            false
          )}
        </div>
      )}
      <h3>Grid Visbility</h3>
      <div className={styles.MarginBottom}>
        Display the visibility of the active grid cells by node or the entire
        site. The cells are filled with the color that matches the corresponding
        visibility percentage.
        {colorRates.map((element, idx) => {
          return (
            <div key={idx} className={styles.LegendWrapper}>
              <div
                className={styles.ColoredSquare}
                style={{ backgroundColor: element.color }}
              ></div>
              <div>{element.legend}</div>
            </div>
          );
        })}
      </div>
      <Form form={form}>
        <Form.Item
          name="visibilityTarget"
          rules={[{ required: true, message: "Please select a node" }]}
        >
          <Select
            value={visibilityTarget}
            onChange={(value: string) => setVisibilityTarget(value)}
            style={{ width: 200 }}
          >
            {siteNodes.map((node) => (
              <Select.Option key={node.NodeId} value={node.NodeId}>
                {node.name}
              </Select.Option>
            ))}
            <Select.Option key={siteGrid.SiteId} value={siteGrid.SiteId}>
              Site
            </Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.MarginBottom}>
          Select a timerange. (The selected day will grab the visibility
          percentage of the 15 days prior to the selected day)
        </div>
        <Form.Item name="targetDate">
          <DatePicker
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
            style={{ width: 200 }}
            value={
              targetDate !== undefined ? dayjs(targetDate as string) : null
            }
            onChange={(date, dateString) => setTargetDate(dateString)}
          />
        </Form.Item>
        <Button type="primary" onClick={onGenerateLayer}>
          Apply
        </Button>
      </Form>
    </div>
  );
};

export default GridVisibilityControls;
