import React, { FC } from "react";
import { Table } from "antd";
import { useGetFieldRepairSchedulesQuery } from "../../store/fieldRepairSchedules/api";

export interface RepairFormValues {
  repairDate: string;
  issueType: "battery" | "cell" | "waterDamage" | "solarCharger" | "other";
  notes?: string;
}

const FieldSchedule: FC = () => {
  const fieldScheduleData = useGetFieldRepairSchedulesQuery(null).data;

  const tableData =
    fieldScheduleData !== undefined ? fieldScheduleData.result : [];
  const columns = [
    {
      title: "Mac Address",
      dataIndex: "macAddress",
      key: "macAddress",
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
    },
    {
      title: "Organization",
      dataIndex: "orgName",
      key: "orgName",
    },
    {
      title: "Scheduled Repair Date",
      dataIndex: "repairDate",
      key: "repairDate",
    },
    {
      title: "Issue Type",
      dataIndex: "issueType",
      key: "issueType",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: boolean) => (value ? "Active" : "Complete"),
    },
  ];

  return (
    <Table
      dataSource={tableData.map((td, index) => ({ ...td, key: index }))}
      columns={columns}
      pagination={{ pageSize: 25 }}
    />
  );
};

export default FieldSchedule;
