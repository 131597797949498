import { createNodePushPin } from "../OrganizationMap/mapApi";
import getCanvasOverlay from "./canvasOverlayHelper";
import { defaultPointObject } from "./helpers";

const bingMapsKey = process.env.REACT_APP_BING_MAP_KEY;

// add this function here because is not compatible with ts
export const getCellMatrix = (g) => {
  const gridObjectToArray = Object.entries(g)
    .filter(([key]) => /^\(\d+,\d+\)$/.test(key))
    .map(([key, value]) => ({
      cellId: key,
      x: parseInt(key.slice(1, key.indexOf(","))),
      y: parseInt(key.slice(key.indexOf(",") + 1, -1)),
      isActive: value.isActive,
      name: value.name ?? "",
      height: value.height ?? "",
      equipment: value.equipment ?? [""]
    }));

  return gridObjectToArray;
};

export const removePushPins = (map, Maps) => {
  for (let i = map.entities.getLength() - 1; i >= 0; i--) {
    const pushpin = map.entities.get(i);
    if (pushpin instanceof Maps.Pushpin) {
      map.entities.removeAt(i);
    }
  }
};

export const addPushpins = (map, Maps, pushPins) => {
  removePushPins(map, Maps);
  pushPins.forEach((pushPin) => {
    if (pushPin === null) {
      return;
    }
    const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
    map.entities.push(newPin);
  });
};

export const removeGrid = (map, Maps) => {
  for (let i = map.entities.getLength() - 1; i >= 0; i--) {
    const mapEntity = map.entities.get(i);
    if (mapEntity instanceof Maps.Polygon) {
      map.entities.removeAt(i);
    }
  }
};

export const setMapViewOptions = (map, Maps, viewOptions) => {
  const options = { ...viewOptions };
  if (viewOptions.mapTypeId) {
    options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
  }
  if (viewOptions.hideRoadLabels) {
    options.labelOverlay = Maps.LabelOverlay.hidden;
  }
  map.setView(options);
};

export const makeMap = (mapRef, MapsRef, mapContainerRef, viewOptions) => {
  if (!MapsRef.current) {
    MapsRef.current = window.Microsoft.Maps;
  }

  const Maps = MapsRef.current;

  // if the map is not assigned to mapRef, it will initialize it.
  // This step prevents multiple map renders.
  // Beware that these functions will work as closures, hence the
  // window object usage
  if (!mapRef.current) {
    mapRef.current = new Maps.Map(mapContainerRef.current, {
      credentials: bingMapsKey
    });

    if (viewOptions) {
      setMapViewOptions(mapRef.current, MapsRef.current, viewOptions);
    }
  }
};

export const cleanGridDrawing = (map, Maps) => {
  removeGrid(map, Maps);
  removePushPins(map, Maps);
  Maps.Events.removeHandler(window.referencePointsCallbackHandlerId);
};

const toggleCell = (map, Maps, e) => {
  const clickedEntity = e.target;
  const selectedCellIndex = window.cellMatrix.findIndex((element) => {
    return element.x === clickedEntity.x && element.y === clickedEntity.y;
  });

  const selectedCellCopy = {
    ...window.cellMatrix[selectedCellIndex],
    isActive: !clickedEntity.isActive
  };

  const cellMatrixCopy = [...window.cellMatrix];

  cellMatrixCopy[selectedCellIndex] = selectedCellCopy;

  window.cellMatrix = cellMatrixCopy;

  const { minLong, maxLong, minLat, maxLat, gridX, gridY } = window.siteGrid;

  const cellCoordinatesKey = `(${clickedEntity.x},${clickedEntity.y})`;

  window.siteGrid[cellCoordinatesKey].isActive = !clickedEntity.isActive;

  drawSiteGrid(
    map,
    Maps,
    cellMatrixCopy,
    minLong,
    maxLong,
    minLat,
    maxLat,
    gridX,
    gridY
  );
};

export const drawSiteGrid = (
  map,
  Maps,
  gMatrix,
  minLong,
  maxLong,
  minLat,
  maxLat,
  gridX,
  gridY,
  clickCallback = true
) => {
  removeGrid(map, Maps);

  const lineColor = new Maps.Color(255, 0, 0, 0); // Black color with full opacity
  const inactiveColor = new Maps.Color(100, 140, 140, 140);
  const activeColor = new Maps.Color(100, 82, 196, 26);

  const cellWidth = (maxLong - minLong) / gridX;
  const cellHeight = (maxLat - minLat) / gridY;

  gMatrix.forEach((cell) => {
    const { x, y, isActive } = cell;
    const cellMinLong = minLong + (x - 1) * cellWidth;
    const cellMaxLong = minLong + x * cellWidth;
    const cellMinLat = minLat + (y - 1) * cellHeight;
    const cellMaxLat = minLat + y * cellHeight;

    const cellRectangle = new Maps.Polygon(
      [
        new Maps.Location(cellMinLat, cellMinLong),
        new Maps.Location(cellMinLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMinLong)
      ],
      {
        strokeColor: lineColor,
        fillColor: isActive ? activeColor : inactiveColor
      }
    );

    cellRectangle.x = x;
    cellRectangle.y = y;
    cellRectangle.isActive = isActive;

    map.entities.push(cellRectangle);

    if (clickCallback) {
      Maps.Events.addHandler(cellRectangle, "click", (e) =>
        toggleCell(map, Maps, e)
      );
    }
  });
};

const onClickMapWhenDrawingGridCallback = (
  location,
  setCurrentSelectedPointCB
) => {
  if (window.currentSelectedPoint === 1) {
    window.pushPins.point1 = location;
  } else {
    window.pushPins.point2 = location;
  }
  setCurrentSelectedPointCB(window.currentSelectedPoint, location);
};

export const removeMapHandler = () => {
  const Maps = window.Microsoft.Maps;
  const map = window.map;
  map.getRootElement().style.cursor = null;
  Maps.Events.removeHandler(window.referencePointsCallbackHandlerId);
};

export const initSinglePushPinClickCallback = (setCurrentSelectedPointCB) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  window.pushPins = {
    point1: defaultPointObject
  };
  window.referencePointsCallbackHandlerId = Maps.Events.addHandler(
    map,
    "click",
    (event) => {
      const point = new Maps.Point(event.getX(), event.getY());
      const location = map.tryPixelToLocation(point);
      onClickMapWhenDrawingGridCallback(
        {
          latitude: location.latitude,
          longitude: location.longitude
        },
        setCurrentSelectedPointCB
      );
      const _pushPins = window.pushPins;
      removePushPins(map, Maps);
      const pushPins = [_pushPins.point1].map((location, index) => {
        const title = `${location.latitude.toFixed(
          6
        )}, ${location.longitude.toFixed(6)}`;
        return {
          center: location,
          options: {
            title
          }
        };
      });
      addPushpins(map, Maps, pushPins);
    }
  );
  map.getRootElement().style.cursor = "pointer";
};

const addNodesLayer = (
  map,
  Maps,
  activeSiteNodes,
  nodePpmForSiteMap,
  showCaughtEvents,
  caughtEventsPushPins,
  showVOCLevels
) => {
  let pushPins = activeSiteNodes.map((node) => {
    return createNodePushPin(node, nodePpmForSiteMap);
  });
  if (showCaughtEvents) {
    pushPins = [...pushPins, ...caughtEventsPushPins];
  }

  removePushPins(map, Maps);

  addPushpins(map, Maps, pushPins);
};

export const handleToggleNodesLayer = (
  map,
  Maps,
  newValue,
  activeSiteNodes,
  nodePpmForSiteMap,
  showCaughtEvents,
  caughtEventsPushPins,
  showVOCLevels
) => {
  if (newValue) {
    addNodesLayer(
      map,
      Maps,
      activeSiteNodes,
      nodePpmForSiteMap,
      showCaughtEvents,
      caughtEventsPushPins,
      showVOCLevels
    );
  } else {
    removePushPins(map, Maps);
    if (showCaughtEvents) {
      caughtEventsPushPins.forEach((pushPin) => {
        if (pushPin === null) {
          return;
        }
        const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
        map.entities.push(newPin);
      });
    }
  }
};

export const initializeMapVariables = () => {
  window.currentSelectedPoint = 1;
  window.pushPins = {
    point1: defaultPointObject,
    point2: defaultPointObject
  };
  window.cellSize = 10;
  window.siteGrid = {};
  window.showSiteGrid = false;
  window.showActiveCells = false;
};

export const drawWindVectors = (
  map,
  drawCallback,
  Maps
) => {
  const CanvasOverlay = getCanvasOverlay(Maps);
  const canvasOverlay = new CanvasOverlay(
    drawCallback
  );
  canvasOverlay.id = "winds-layer";
  map.layers.insert(canvasOverlay);
};

export const removeWindVector = () => {
  const map = window.map;
  const mapLayers = map.layers;
  const windsLayer = mapLayers.find((l) => l.id === "winds-layer");
  if (windsLayer) {
    mapLayers.remove(windsLayer);
  }
};

export const handleToggleWindsLayer = (
  map,
  Maps,
  newValue,
  drawCallback
) => {
  if (newValue) {
    removeWindVector(map);
    drawWindVectors(map, drawCallback, Maps);
  } else {
    removeWindVector(map);
  }
};

export const getMapsColor = (a, r, g, b) => {
  const Maps = window.Microsoft.Maps;
  const color = new Maps.Color(a, r, g, b);
  return color;
};
