import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Authenticator, useAuthenticator, Button } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

import EVLogo from "./assets/ev_logo.png";
import NewOrganization from "./pages/Organizations/NewOrganization/NewOrganization";
import NewSite from "./pages/Sites/NewSite/NewSite";

import styles from "./styles.module.scss";
import "@aws-amplify/ui-react/styles.css";
import Users from "./pages/Users/Users";
import Devices from "./pages/Devices/Devices";
import { useAppDispatch } from "./store/hooks";
import { onSignOut, setCurrentUser } from "./store/auth/slice";
import CustomerSites from "./pages/CustomerSites/CustomerSites";
import EditOrganization from "./pages/Organizations/EditOrganization/EditOrganization";
import LayoutV2 from "./components/LayoutV2/LayoutV2";
import OrganizationMap from "./pages/OrganizationMap/OrganizationMap";
import { FC } from "react";
import SingleNodeSite from "./pages/Sites/Site_sn/Site_sn";
import Emissions from "./pages/Emissions/Emissions";
import Organizations from "./pages/Organizations/Organizations";
import Sites from "./pages/Sites/Sites";
import Analytics from "./pages/Analytics/Analytics";
import OrgAlarmsV2 from "./pages/OrgAlarmsV2/OrgAlarmsV2";
import KPIReport from "./pages/KPIReport/KPIReport";

interface AppBodyProps {
  children: JSX.Element;
}

interface SignInInput {
  username: string;
  password: string;
}

type ForgotPasswordInput = string;

interface ForgotPasswordSubmitInput {
  username: string;
  code: string;
  password: string;
}

const components = {
  Header() {
    return (
      <div className={styles.AuthHeader}>
        <img src={EVLogo} />
      </div>
    );
  },
  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className={styles.AuthFooter}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            type="button"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Forgot Your Password?
          </Button>
          <br />
          <div className={styles.AuthSupport}>
            For assistance, please contact us at{" "}
            <a href="mailto:info@earthview.io">info@earthview.io</a>.
          </div>
        </div>
      );
    },
  },
};

const AppBody: FC<AppBodyProps> = ({ children }) => {
  return (
    <div
      style={{
        margin: 10,
        padding: 18,
        paddingTop: 10,
        background: "white",
        borderRadius: 10,
        height: "98%",
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
};

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const services = {
    async handleSignIn(input: SignInInput) {
      const { username, password } = input;
      const lowerUsername = username.toLowerCase();
      return await Auth.signIn(lowerUsername, password);
    },
    async handleForgotPassword(username: ForgotPasswordInput) {
      const lowerUsername = username.toLowerCase();
      return await Auth.forgotPassword(lowerUsername);
    },
    async handleForgotPasswordSubmit(input: ForgotPasswordSubmitInput) {
      const { username, code, password } = input;
      const lowerUsername = username.toLowerCase();
      return await Auth.forgotPasswordSubmit(lowerUsername, code, password);
    },
  };
  return (
    <div className={styles.AppWrapper}>
      <Authenticator hideSignUp components={components} services={services}>
        {(res) => {
          const { signOut } = res;

          let userId = "";

          if (res.user !== null && res.user !== undefined) {
            const user = res.user;
            const username: string =
              user.username !== undefined ? user.username : "";
            const session = user?.getSignInUserSession();
            const authToken = session?.getIdToken().getJwtToken();
            if (user?.attributes?.sub !== undefined) {
              userId = user.attributes.sub;
            }
            dispatch(
              setCurrentUser({
                currentUser: username,
                token: authToken,
                userId,
              })
            );
          }

          let placeholderSignOut = function (): void {
            /* placeholder */
          };
          if (signOut !== undefined) {
            placeholderSignOut = () => {
              dispatch(onSignOut(null));
              signOut();
            };
          }

          return (
            <BrowserRouter>
              <LayoutV2 signOut={placeholderSignOut} userId={userId}>
                <Routes>
                  <Route path="/" element={<OrganizationMap />} />
                  <Route
                    path="sites/:id"
                    element={
                      <AppBody>
                        <SingleNodeSite />
                      </AppBody>
                    }
                  ></Route>
                  <Route
                    path="sites/:id/emissions"
                    element={
                      <AppBody>
                        <Emissions />
                      </AppBody>
                    }
                  ></Route>
                  <Route
                    path="/organizations"
                    element={
                      <AppBody>
                        <Organizations />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/organization/:id/sites"
                    element={
                      <AppBody>
                        <Sites />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/organizations/new"
                    element={
                      <AppBody>
                        <NewOrganization />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/organizations/:id/edit"
                    element={
                      <AppBody>
                        <EditOrganization />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/sites/new"
                    element={
                      <AppBody>
                        <NewSite />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <AppBody>
                        <Users />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/devices"
                    element={
                      <AppBody>
                        <Devices isTest={false} />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/userSites"
                    element={
                      <AppBody>
                        <CustomerSites />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/analytics"
                    element={
                      <AppBody>
                        <Analytics />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/incidents"
                    element={
                      <AppBody>
                        <OrgAlarmsV2 />
                      </AppBody>
                    }
                  />
                  <Route
                    path="/kpi-reports"
                    element={
                      <AppBody>
                        <KPIReport />
                      </AppBody>
                    }
                  />
                </Routes>
              </LayoutV2>
            </BrowserRouter>
          );
        }}
      </Authenticator>
    </div>
  );
}

export default App;
