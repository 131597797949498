import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { CreateFieldRepairScheduleResponse, FieldRepairScheduleItem, FieldRepairSchedulesResponse } from "./types";

export const fieldRepairSchedulesApi = createApi({
  reducerPath: "fieldRepairSchedulesApi",
  baseQuery,
  tagTypes: ["FieldRepairSchedule"],
  endpoints: (builder) => ({
    getFieldRepairSchedules: builder.query<FieldRepairSchedulesResponse, null>({
      query: () => "fieldRepairSchedules",
      providesTags: ["FieldRepairSchedule"],
    }),
    createFieldRepairSchedule: builder.mutation<
    CreateFieldRepairScheduleResponse,
    FieldRepairScheduleItem
    >({
      query: (payload) => ({
        url: "fieldRepairSchedules",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetFieldRepairSchedulesQuery, useCreateFieldRepairScheduleMutation } = fieldRepairSchedulesApi;
